import React, { useState } from "react";

export default function AddPrices() {
  const [message, setMessage] = useState("Updating Prices, Please Wait...");
  const [display, setDisplay] = useState("none");

  const [details, setDetails] = useState({
    PriceDate: "",
    bitcoinPriceToday: "",
    bitcoinPriceTomorrow: "",
    isBitcoinUp: "",
    ethereumPriceToday: "",
    ethereumPriceTomorrow: "",
    isEthereumUp: "",
    thetherPriceToday: "",
    thetherPriceTomorrow: "",
    isThetherUp: "",
    solanaPriceToday: "",
    solanaPriceTomorrow: "",
    isSolanaUp: "",
    bnbPriceToday: "",
    bnbPriceTomorrow: "",
    isBnbUp: "",
    xrpPriceToday: "",
    xrpPriceTomorrow: "",
    isXrpUp: "",
    usdCoinPriceToday: "",
    usdCoinPriceTomorrow: "",
    isUsdCoinUp: "",
    cardonaPriceToday: "",
    cardonaPriceTomorrow: "",
    isCardonaUp: "",
    avalanchePriceToday: "",
    avalanchePriceTomorrow: "",
    isAvalancheUp: "",
    dogeCoinPriceToday: "",
    dogeCoinPriceTomorrow: "",
    isDogeCoinUp: "",
    polkadotPriceToday: "",
    polkadotPriceTomorrow: "",
    isPolkadotUp: "",
    tronPriceToday: "",
    tronPriceTomorrow: "",
    isTronUp: "",
    polygonPriceToday: "",
    polygonPriceTomorrow: "",
    isPolygonUp: "",
    chainlinkPriceToday: "",
    chainlinkPriceTomorrow: "",
    isChainlinkUp: "",
    toncoinPriceToday: "",
    toncoinPriceTomorrow: "",
    isToncoinUp: "",
    wrappedBitcoinPriceToday: "",
    wrappedBitcoinPriceTomorrow: "",
    isWrappedBitcoinUp: "",
    shibaInuPriceToday: "",
    shibaInuPriceTomorrow: "",
    isShibaInuUp: "",
    litecoinPriceToday: "",
    litecoinPriceTomorrow: "",
    isLitecoinUp: "",
    daiPriceToday: "",
    daiPriceTomorrow: "",
    isDaiUp: "",
    bitcoinCashPriceToday: "",
    bitcoinCashPriceTomorrow: "",
    isBitcoinCashUp: "",
    cosmosPriceToday: "",
    cosmosPriceTomorrow: "",
    isCosmosUp: "",
    uniswapPriceToday: "",
    uniswapPriceTomorrow: "",
    isUniswapUp: "",
    nearProtocolPriceToday: "",
    nearProtocolPriceTomorrow: "",
    isNearProtocolUp: "",
    internetProtocolPriceToday: "",
    internetProtocolPriceTomorrow: "",
    isInternetProtocolUp: "",
    unusSedLeoPriceToday: "",
    unusSedLeoPriceTomorrow: "",
    isUnusSedLeoUp: "",
    stellerPriceToday: "",
    stellerPriceTomorrow: "",
    isStellerUp: "",
    okbPriceToday: "",
    okbPriceTomorrow: "",
    isOkbUp: "",
    injectivePriceToday: "",
    injectivePriceTomorrow: "",
    isInjectiveUp: "",
    moneroPriceToday: "",
    moneroPriceTomorrow: "",
    isMoneroUp: "",
    aptosPriceToday: "",
    aptosPriceTomorrow: "",
    isAptosUp: "",
    ethereumClassicPriceToday: "",
    ethereumClassicPriceTomorrow: "",
    isEthereumClassicUp: "",
    hederaPriceToday: "",
    hederaPriceTomorrow: "",
    isHederaUp: "",
    immutableXPriceToday: "",
    immutableXPriceTomorrow: "",
    isImmutableXUp: "",
    optimismPriceToday: "",
    optimismPriceTomorrow: "",
    isOptimismUp: "",
    filecoinPriceToday: "",
    filecoinPriceTomorrow: "",
    isFilecoinUp: "",
    veChainPriceToday: "",
    veChainPriceTomorrow: "",
    isVeChainUp: "",
    cronosPriceToday: "",
    cronosPriceTomorrow: "",
    isCronosUp: "",
    trueUsdPriceToday: "",
    trueUsdPriceTomorrow: "",
    isTrueUsdUp: "",
    kaspaPriceToday: "",
    kaspaPriceTomorrow: "",
    isKaspaUp: "",
    lidoDaoTokenPriceToday: "",
    lidoDaoTokenPriceTomorrow: "",
    isLidoDaoTokenUp: "",
    stacksPriceToday: "",
    stacksPriceTomorrow: "",
    isStacksUp: "",
    celestiaPriceToday: "",
    celestiaPriceTomorrow: "",
    isCelestiaUp: "",
    algorandPriceToday: "",
    algorandPriceTomorrow: "",
    isAlgorandUp: "",
    mantlePriceToday: "",
    mantlePriceTomorrow: "",
    isMantleUp: "",
    thorChainPriceToday: "",
    thorChainPriceTomorrow: "",
    isThorChainUp: "",
    multiversXPriceToday: "",
    multiversXPriceTomorrow: "",
    isMultiversXUp: "",
    renderTokenPriceToday: "",
    renderTokenPriceTomorrow: "",
    isRenderTokenUp: "",
    theGraphPriceToday: "",
    theGraphPriceTomorrow: "",
    isTheGraphUp: "",
    firstDigitalUsdPriceToday: "",
    firstDigitalUsdPriceTomorrow: "",
    isFirstDigitalUsdUp: "",
    quantPriceToday: "",
    quantPriceTomorrow: "",
    isQuantUp: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const submitBtn = async () => {
    setMessage("Updating Prices, Please Wait...");
    setDisplay("block");

    try {
      const url =
        "https://cryptopredicts.live:1000/api/v1/price/updatePrices/658eff698e7ec3efa1e25ad3";
      const data = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PriceDate: details.PriceDate.replace(/\s+$/, ""),
          bitcoinPriceToday: details.bitcoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          bitcoinPriceTomorrow: details.bitcoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isBitcoinUp: `${
            details.isBitcoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          ethereumPriceToday: details.ethereumPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          ethereumPriceTomorrow: details.ethereumPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isEthereumUp: `${
            details.isEthereumUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          thetherPriceToday: details.thetherPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          thetherPriceTomorrow: details.thetherPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isThetherUp: `${
            details.isThetherUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          solanaPriceToday: details.solanaPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          solanaPriceTomorrow: details.solanaPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isSolanaUp: `${
            details.isSolanaUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          bnbPriceToday: details.bnbPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          bnbPriceTomorrow: details.bnbPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isBnbUp: `${
            details.isBnbUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          xrpPriceToday: details.xrpPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          xrpPriceTomorrow: details.xrpPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isXrpUp: `${
            details.isXrpUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          usdCoinPriceToday: details.usdCoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          usdCoinPriceTomorrow: details.usdCoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isUsdCoinUp: `${
            details.isUsdCoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          cardonaPriceToday: details.cardonaPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          cardonaPriceTomorrow: details.cardonaPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isCardonaUp: `${
            details.isCardonaUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          avalanchePriceToday: details.avalanchePriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          avalanchePriceTomorrow: details.avalanchePriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isAvalancheUp: `${
            details.isAvalancheUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          dogeCoinPriceToday: details.dogeCoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          dogeCoinPriceTomorrow: details.dogeCoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isDogeCoinUp: `${
            details.isDogeCoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          polkadotPriceToday: details.polkadotPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          polkadotPriceTomorrow: details.polkadotPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isPolkadotUp: `${
            details.isPolkadotUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,

          bitcoinCashPriceToday: details.bitcoinCashPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          bitcoinCashPriceTomorrow: details.bitcoinCashPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isBitcoinCashUp: `${
            details.isBitcoinCashUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          cosmosPriceToday: details.cosmosPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          cosmosPriceTomorrow: details.cosmosPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isCosmosUp: `${
            details.isCosmosUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          uniswapPriceToday: details.uniswapPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          uniswapPriceTomorrow: details.uniswapPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isUniswapUp: `${
            details.isUniswapUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          nearProtocolPriceToday: details.nearProtocolPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          nearProtocolPriceTomorrow: details.nearProtocolPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isNearProtocolUp: `${
            details.isNearProtocolUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          internetProtocolPriceToday: details.internetProtocolPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          internetProtocolPriceTomorrow: details.internetProtocolPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isInternetProtocolUp: `${
            details.isInternetProtocolUp.toLowerCase().replace(/\s+$/, "") ===
            "yes"
              ? true
              : false
          }`,
          unusSedLeoPriceToday: details.unusSedLeoPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          unusSedLeoPriceTomorrow: details.unusSedLeoPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isUnusSedLeoUp: `${
            details.isUnusSedLeoUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          stellerPriceToday: details.stellerPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          stellerPriceTomorrow: details.stellerPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isStellerUp: `${
            details.isStellerUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          okbPriceToday: details.okbPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          okbPriceTomorrow: details.okbPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isOkbUp: `${
            details.isOkbUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          injectivePriceToday: details.injectivePriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          injectivePriceTomorrow: details.injectivePriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isInjectiveUp: `${
            details.isInjectiveUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          moneroPriceToday: details.moneroPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          moneroPriceTomorrow: details.moneroPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isMoneroUp: `${
            details.isMoneroUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          aptosPriceToday: details.aptosPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          aptosPriceTomorrow: details.aptosPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isAptosUp: `${
            details.isAptosUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          ethereumClassicPriceToday: details.ethereumClassicPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          ethereumClassicPriceTomorrow: details.ethereumClassicPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isEthereumClassicUp: `${
            details.isEthereumClassicUp.toLowerCase().replace(/\s+$/, "") ===
            "yes"
              ? true
              : false
          }`,
          hederaPriceToday: details.hederaPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          hederaPriceTomorrow: details.hederaPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isHederaUp: `${
            details.isHederaUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          immutableXPriceToday: details.immutableXPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          immutableXPriceTomorrow: details.immutableXPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isImmutableXUp: `${
            details.isImmutableXUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          optimismPriceToday: details.optimismPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          optimismPriceTomorrow: details.optimismPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isOptimismUp: `${
            details.isOptimismUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          filecoinPriceToday: details.filecoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          filecoinPriceTomorrow: details.filecoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isFilecoinUp: `${
            details.isFilecoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          veChainPriceToday: details.veChainPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          veChainPriceTomorrow: details.veChainPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isVeChainUp: `${
            details.isVeChainUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          cronosPriceToday: details.cronosPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          cronosPriceTomorrow: details.cronosPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isCronosUp: `${
            details.isCronosUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          trueUsdPriceToday: details.trueUsdPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          trueUsdPriceTomorrow: details.trueUsdPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isTrueUsdUp: `${
            details.isTrueUsdUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          kaspaPriceToday: details.kaspaPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          kaspaPriceTomorrow: details.kaspaPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isKaspaUp: `${
            details.isKaspaUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          lidoDaoTokenPriceToday: details.lidoDaoTokenPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          lidoDaoTokenPriceTomorrow: details.lidoDaoTokenPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isLidoDaoTokenUp: `${
            details.isLidoDaoTokenUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          stacksPriceToday: details.stacksPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          stacksPriceTomorrow: details.stacksPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isStacksUp: `${
            details.isStacksUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          celestiaPriceToday: details.celestiaPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          celestiaPriceTomorrow: details.celestiaPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isCelestiaUp: `${
            details.isCelestiaUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          algorandPriceToday: details.algorandPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          algorandPriceTomorrow: details.algorandPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isAlgorandUp: `${
            details.isAlgorandUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          mantlePriceToday: details.mantlePriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          mantlePriceTomorrow: details.mantlePriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isMantleUp: `${
            details.isMantleUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          thorChainPriceToday: details.thorChainPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          thorChainPriceTomorrow: details.thorChainPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isThorChainUp: `${
            details.isThorChainUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          multiversXPriceToday: details.multiversXPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          multiversXPriceTomorrow: details.multiversXPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isMultiversXUp: `${
            details.isMultiversXUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          renderTokenPriceToday: details.renderTokenPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          renderTokenPriceTomorrow: details.renderTokenPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isRenderTokenUp: `${
            details.isRenderTokenUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          theGraphPriceToday: details.theGraphPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          theGraphPriceTomorrow: details.theGraphPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isTheGraphUp: `${
            details.isTheGraphUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          firstDigitalUsdPriceToday: details.firstDigitalUsdPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          firstDigitalUsdPriceTomorrow: details.firstDigitalUsdPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isFirstDigitalUsdUp: `${
            details.isFirstDigitalUsdUp.toLowerCase().replace(/\s+$/, "") ===
            "yes"
              ? true
              : false
          }`,
          quantPriceToday: details.quantPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          quantPriceTomorrow: details.quantPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isQuantUp: `${
            details.isQuantUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          tronPriceToday: details.tronPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          tronPriceTomorrow: details.tronPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isTronUp: `${
            details.isTronUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          polygonPriceToday: details.polygonPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          polygonPriceTomorrow: details.polygonPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isPolygonUp: `${
            details.isPolygonUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          wrappedBitcoinPriceToday: details.wrappedBitcoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          wrappedBitcoinPriceTomorrow: details.wrappedBitcoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isWrappedBitcoinUp: `${
            details.isWrappedBitcoinUp.toLowerCase().replace(/\s+$/, "") ===
            "yes"
              ? true
              : false
          }`,
          shibaInuPriceToday: details.shibaInuPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          shibaInuPriceTomorrow: details.shibaInuPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isShibaInuUp: `${
            details.isShibaInuUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          daiPriceToday: details.daiPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          daiPriceTomorrow: details.daiPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isDaiUp: `${
            details.isDaiUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          chainlinkPriceToday: details.chainlinkPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          chainlinkPriceTomorrow: details.chainlinkPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isChainlinkUp: `${
            details.isChainlinkUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          toncoinPriceToday: details.toncoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          toncoinPriceTomorrow: details.toncoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isToncoinUp: `${
            details.isToncoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
          litecoinPriceToday: details.litecoinPriceToday
            .toLowerCase()
            .replace(/\s+$/, ""),
          litecoinPriceTomorrow: details.litecoinPriceTomorrow
            .toLowerCase()
            .replace(/\s+$/, ""),
          isLitecoinUp: `${
            details.isLitecoinUp.toLowerCase().replace(/\s+$/, "") === "yes"
              ? true
              : false
          }`,
        }),
      });
      const response = await data.json();
      console.log(response);

      alert("Prices Updated Successfully");
    } catch (e) {
      console.log(e);
      alert(
        "Some error occurred while updating prices. Please check your connection and try again"
      );
    }
  };

  const alert = (message) => {
    setMessage(`${message}`);
    setTimeout(() => {
      setDisplay("none");
    }, 8000);
  };

  return (
    <div>
      <div className="container my-2">
        <h1 className="text-center my-2">Update Prices</h1>
        <div class="mb-3 my-3">
          <label for="PriceDate" class="form-label">
            Tomorrow Date
          </label>
          <input
            type="text"
            class="form-control"
            id="PriceDate"
            name="PriceDate"
            onChange={onChange}
            value={details.PriceDate}
            placeholder="write tomorrow date like 10 January 2024"
          />
        </div>
        <h3>Bitcoin</h3>
        <div class="mb-3 my-3">
          <label for="bitcoinPriceToday" class="form-label">
            Bitcoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="bitcoinPriceToday"
            name="bitcoinPriceToday"
            onChange={onChange}
            value={details.bitcoinPriceToday}
            placeholder="write bitcoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="bitcoinPriceTomorrow" class="form-label">
            Bitcoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="bitcoinPriceTomorrow"
            name="bitcoinPriceTomorrow"
            onChange={onChange}
            value={details.bitcPriceTomorrow}
            placeholder="write bitcoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isBitcoinUp" class="form-label">
            Is bitcoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isBitcoinUp"
            name="isBitcoinUp"
            onChange={onChange}
            value={details.isBitcoinUp}
            placeholder="yes or no"
          />
        </div>

        <h3>Ethereum</h3>
        <div class="mb-3 my-3">
          <label for="ethereumPriceToday" class="form-label">
            Ethereum Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="ethereumPriceToday"
            name="ethereumPriceToday"
            onChange={onChange}
            value={details.ethereumPriceToday}
            placeholder="Write Ethereum's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="ethereumPriceTomorrow" class="form-label">
            Ethereum Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="ethereumPriceTomorrow"
            name="ethereumPriceTomorrow"
            onChange={onChange}
            value={details.ethereumPriceTomorrow}
            placeholder="Write Ethereum's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isEthereumUp" class="form-label">
            Is Ethereum going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isEthereumUp"
            name="isEthereumUp"
            onChange={onChange}
            value={details.isEthereumUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Tether</h3>
        <div class="mb-3 my-3">
          <label for="thetherPriceToday" class="form-label">
            Tether Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="thetherPriceToday"
            name="thetherPriceToday"
            onChange={onChange}
            value={details.thetherPriceToday}
            placeholder="Write Tether's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="thetherPriceTomorrow" class="form-label">
            Tether Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="thetherPriceTomorrow"
            name="thetherPriceTomorrow"
            onChange={onChange}
            value={details.thetherPriceTomorrow}
            placeholder="Write Tether's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isThetherUp" class="form-label">
            Is Tether going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isThetherUp"
            name="isThetherUp"
            onChange={onChange}
            value={details.isThetherUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Solana</h3>
        <div class="mb-3 my-3">
          <label for="solanaPriceToday" class="form-label">
            Solana Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="solanaPriceToday"
            name="solanaPriceToday"
            onChange={onChange}
            value={details.solanaPriceToday}
            placeholder="Write Solana's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="solanaPriceTomorrow" class="form-label">
            Solana Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="solanaPriceTomorrow"
            name="solanaPriceTomorrow"
            onChange={onChange}
            value={details.solanaPriceTomorrow}
            placeholder="Write Solana's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isSolanaUp" class="form-label">
            Is Solana going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isSolanaUp"
            name="isSolanaUp"
            onChange={onChange}
            value={details.isSolanaUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Binance Coin (BNB)</h3>
        <div class="mb-3 my-3">
          <label for="bnbPriceToday" class="form-label">
            BNB Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="bnbPriceToday"
            name="bnbPriceToday"
            onChange={onChange}
            value={details.bnbPriceToday}
            placeholder="Write BNB's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="bnbPriceTomorrow" class="form-label">
            BNB Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="bnbPriceTomorrow"
            name="bnbPriceTomorrow"
            onChange={onChange}
            value={details.bnbPriceTomorrow}
            placeholder="Write BNB's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isBnbUp" class="form-label">
            Is BNB going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isBnbUp"
            name="isBnbUp"
            onChange={onChange}
            value={details.isBnbUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>XRP</h3>
        <div class="mb-3 my-3">
          <label for="xrpPriceToday" class="form-label">
            XRP Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="xrpPriceToday"
            name="xrpPriceToday"
            onChange={onChange}
            value={details.xrpPriceToday}
            placeholder="Write XRP's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="xrpPriceTomorrow" class="form-label">
            XRP Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="xrpPriceTomorrow"
            name="xrpPriceTomorrow"
            onChange={onChange}
            value={details.xrpPriceTomorrow}
            placeholder="Write XRP's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isXrpUp" class="form-label">
            Is XRP going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isXrpUp"
            name="isXrpUp"
            onChange={onChange}
            value={details.isXrpUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>USD Coin</h3>
        <div class="mb-3 my-3">
          <label for="usdCoinPriceToday" class="form-label">
            USD Coin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="usdCoinPriceToday"
            name="usdCoinPriceToday"
            onChange={onChange}
            value={details.usdCoinPriceToday}
            placeholder="Write USD Coin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="usdCoinPriceTomorrow" class="form-label">
            USD Coin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="usdCoinPriceTomorrow"
            name="usdCoinPriceTomorrow"
            onChange={onChange}
            value={details.usdCoinPriceTomorrow}
            placeholder="Write USD Coin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isUsdCoinUp" class="form-label">
            Is USD Coin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isUsdCoinUp"
            name="isUsdCoinUp"
            onChange={onChange}
            value={details.isUsdCoinUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Cardano</h3>
        <div class="mb-3 my-3">
          <label for="cardonaPriceToday" class="form-label">
            Cardano Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="cardonaPriceToday"
            name="cardonaPriceToday"
            onChange={onChange}
            value={details.cardonaPriceToday}
            placeholder="Write Cardano's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="cardonaPriceTomorrow" class="form-label">
            Cardano Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="cardonaPriceTomorrow"
            name="cardonaPriceTomorrow"
            onChange={onChange}
            value={details.cardonaPriceTomorrow}
            placeholder="Write Cardano's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isCardonaUp" class="form-label">
            Is Cardano going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isCardonaUp"
            name="isCardonaUp"
            onChange={onChange}
            value={details.isCardonaUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Avalanche</h3>
        <div class="mb-3 my-3">
          <label for="avalanchePriceToday" class="form-label">
            Avalanche Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="avalanchePriceToday"
            name="avalanchePriceToday"
            onChange={onChange}
            value={details.avalanchePriceToday}
            placeholder="Write Avalanche's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="avalanchePriceTomorrow" class="form-label">
            Avalanche Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="avalanchePriceTomorrow"
            name="avalanchePriceTomorrow"
            onChange={onChange}
            value={details.avalanchePriceTomorrow}
            placeholder="Write Avalanche's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isAvalancheUp" class="form-label">
            Is Avalanche going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isAvalancheUp"
            name="isAvalancheUp"
            onChange={onChange}
            value={details.isAvalancheUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Dogecoin</h3>
        <div class="mb-3 my-3">
          <label for="dogeCoinPriceToday" class="form-label">
            Dogecoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="dogeCoinPriceToday"
            name="dogeCoinPriceToday"
            onChange={onChange}
            value={details.dogeCoinPriceToday}
            placeholder="Write Dogecoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="dogeCoinPriceTomorrow" class="form-label">
            Dogecoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="dogeCoinPriceTomorrow"
            name="dogeCoinPriceTomorrow"
            onChange={onChange}
            value={details.dogeCoinPriceTomorrow}
            placeholder="Write Dogecoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isDogeCoinUp" class="form-label">
            Is Dogecoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isDogeCoinUp"
            name="isDogeCoinUp"
            onChange={onChange}
            value={details.isDogeCoinUp}
            placeholder="Yes or No"
          />
        </div>
        <h3>Polkadot</h3>
        <div class="mb-3 my-3">
          <label for="polkadotPriceToday" class="form-label">
            Polkadot Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="polkadotPriceToday"
            name="polkadotPriceToday"
            onChange={onChange}
            value={details.polkadotPriceToday}
            placeholder="Write Polkadot's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="polkadotPriceTomorrow" class="form-label">
            Polkadot Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="polkadotPriceTomorrow"
            name="polkadotPriceTomorrow"
            onChange={onChange}
            value={details.polkadotPriceTomorrow}
            placeholder="Write Polkadot's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isPolkadotUp" class="form-label">
            Is Polkadot going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isPolkadotUp"
            name="isPolkadotUp"
            onChange={onChange}
            value={details.isPolkadotUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Tron</h3>
        <div class="mb-3 my-3">
          <label for="tronPriceToday" class="form-label">
            Tron Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="tronPriceToday"
            name="tronPriceToday"
            onChange={onChange}
            value={details.tronPriceToday}
            placeholder="Write Tron's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="tronPriceTomorrow" class="form-label">
            Tron Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="tronPriceTomorrow"
            name="tronPriceTomorrow"
            onChange={onChange}
            value={details.tronPriceTomorrow}
            placeholder="Write Tron's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isTronUp" class="form-label">
            Is Tron going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isTronUp"
            name="isTronUp"
            onChange={onChange}
            value={details.isTronUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Polygon</h3>
        <div class="mb-3 my-3">
          <label for="polygonPriceToday" class="form-label">
            Polygon Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="polygonPriceToday"
            name="polygonPriceToday"
            onChange={onChange}
            value={details.polygonPriceToday}
            placeholder="Write Polygon's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="polygonPriceTomorrow" class="form-label">
            Polygon Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="polygonPriceTomorrow"
            name="polygonPriceTomorrow"
            onChange={onChange}
            value={details.polygonPriceTomorrow}
            placeholder="Write Polygon's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isPolygonUp" class="form-label">
            Is Polygon going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isPolygonUp"
            name="isPolygonUp"
            onChange={onChange}
            value={details.isPolygonUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Chainlink</h3>
        <div class="mb-3 my-3">
          <label for="chainlinkPriceToday" class="form-label">
            Chainlink Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="chainlinkPriceToday"
            name="chainlinkPriceToday"
            onChange={onChange}
            value={details.chainlinkPriceToday}
            placeholder="Write Chainlink's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="chainlinkPriceTomorrow" class="form-label">
            Chainlink Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="chainlinkPriceTomorrow"
            name="chainlinkPriceTomorrow"
            onChange={onChange}
            value={details.chainlinkPriceTomorrow}
            placeholder="Write Chainlink's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isChainlinkUp" class="form-label">
            Is Chainlink going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isChainlinkUp"
            name="isChainlinkUp"
            onChange={onChange}
            value={details.isChainlinkUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>TONcoin</h3>
        <div class="mb-3 my-3">
          <label for="toncoinPriceToday" class="form-label">
            TONcoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="toncoinPriceToday"
            name="toncoinPriceToday"
            onChange={onChange}
            value={details.toncoinPriceToday}
            placeholder="Write TONcoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="toncoinPriceTomorrow" class="form-label">
            TONcoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="toncoinPriceTomorrow"
            name="toncoinPriceTomorrow"
            onChange={onChange}
            value={details.toncoinPriceTomorrow}
            placeholder="Write TONcoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isToncoinUp" class="form-label">
            Is TONcoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isToncoinUp"
            name="isToncoinUp"
            onChange={onChange}
            value={details.isToncoinUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Wrapped Bitcoin</h3>
        <div class="mb-3 my-3">
          <label for="wrappedBitcoinPriceToday" class="form-label">
            Wrapped Bitcoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="wrappedBitcoinPriceToday"
            name="wrappedBitcoinPriceToday"
            onChange={onChange}
            value={details.wrappedBitcoinPriceToday}
            placeholder="Write Wrapped Bitcoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="wrappedBitcoinPriceTomorrow" class="form-label">
            Wrapped Bitcoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="wrappedBitcoinPriceTomorrow"
            name="wrappedBitcoinPriceTomorrow"
            onChange={onChange}
            value={details.wrappedBitcoinPriceTomorrow}
            placeholder="Write Wrapped Bitcoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isWrappedBitcoinUp" class="form-label">
            Is Wrapped Bitcoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isWrappedBitcoinUp"
            name="isWrappedBitcoinUp"
            onChange={onChange}
            value={details.isWrappedBitcoinUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Shiba Inu</h3>
        <div class="mb-3 my-3">
          <label for="shibaInuPriceToday" class="form-label">
            Shiba Inu Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="shibaInuPriceToday"
            name="shibaInuPriceToday"
            onChange={onChange}
            value={details.shibaInuPriceToday}
            placeholder="Write Shiba Inu's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="shibaInuPriceTomorrow" class="form-label">
            Shiba Inu Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="shibaInuPriceTomorrow"
            name="shibaInuPriceTomorrow"
            onChange={onChange}
            value={details.shibaInuPriceTomorrow}
            placeholder="Write Shiba Inu's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isShibaInuUp" class="form-label">
            Is Shiba Inu going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isShibaInuUp"
            name="isShibaInuUp"
            onChange={onChange}
            value={details.isShibaInuUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Litecoin</h3>
        <div class="mb-3 my-3">
          <label for="litecoinPriceToday" class="form-label">
            Litecoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="litecoinPriceToday"
            name="litecoinPriceToday"
            onChange={onChange}
            value={details.litecoinPriceToday}
            placeholder="Write Litecoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="litecoinPriceTomorrow" class="form-label">
            Litecoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="litecoinPriceTomorrow"
            name="litecoinPriceTomorrow"
            onChange={onChange}
            value={details.litecoinPriceTomorrow}
            placeholder="Write Litecoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isLitecoinUp" class="form-label">
            Is Litecoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isLitecoinUp"
            name="isLitecoinUp"
            onChange={onChange}
            value={details.isLitecoinUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>DAI</h3>
        <div class="mb-3 my-3">
          <label for="daiPriceToday" class="form-label">
            DAI Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="daiPriceToday"
            name="daiPriceToday"
            onChange={onChange}
            value={details.daiPriceToday}
            placeholder="Write DAI's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="daiPriceTomorrow" class="form-label">
            DAI Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="daiPriceTomorrow"
            name="daiPriceTomorrow"
            onChange={onChange}
            value={details.daiPriceTomorrow}
            placeholder="Write DAI's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isDaiUp" class="form-label">
            Is DAI going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isDaiUp"
            name="isDaiUp"
            onChange={onChange}
            value={details.isDaiUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Bitcoin Cash (BCH)</h3>
        <div class="mb-3 my-3">
          <label for="bitcoinCashPriceToday" class="form-label">
            Bitcoin Cash Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="bitcoinCashPriceToday"
            name="bitcoinCashPriceToday"
            onChange={onChange}
            value={details.bitcoinCashPriceToday}
            placeholder="Write Bitcoin Cash's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="bitcoinCashPriceTomorrow" class="form-label">
            Bitcoin Cash Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="bitcoinCashPriceTomorrow"
            name="bitcoinCashPriceTomorrow"
            onChange={onChange}
            value={details.bitcoinCashPriceTomorrow}
            placeholder="Write Bitcoin Cash's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isBitcoinCashUp" class="form-label">
            Is Bitcoin Cash going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isBitcoinCashUp"
            name="isBitcoinCashUp"
            onChange={onChange}
            value={details.isBitcoinCashUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Cosmos (ATOM)</h3>
        <div class="mb-3 my-3">
          <label for="cosmosPriceToday" class="form-label">
            Cosmos Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="cosmosPriceToday"
            name="cosmosPriceToday"
            onChange={onChange}
            value={details.cosmosPriceToday}
            placeholder="Write Cosmos' today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="cosmosPriceTomorrow" class="form-label">
            Cosmos Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="cosmosPriceTomorrow"
            name="cosmosPriceTomorrow"
            onChange={onChange}
            value={details.cosmosPriceTomorrow}
            placeholder="Write Cosmos' tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isCosmosUp" class="form-label">
            Is Cosmos going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isCosmosUp"
            name="isCosmosUp"
            onChange={onChange}
            value={details.isCosmosUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Uniswap (UNI)</h3>
        <div class="mb-3 my-3">
          <label for="uniswapPriceToday" class="form-label">
            Uniswap Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="uniswapPriceToday"
            name="uniswapPriceToday"
            onChange={onChange}
            value={details.uniswapPriceToday}
            placeholder="Write Uniswap's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="uniswapPriceTomorrow" class="form-label">
            Uniswap Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="uniswapPriceTomorrow"
            name="uniswapPriceTomorrow"
            onChange={onChange}
            value={details.uniswapPriceTomorrow}
            placeholder="Write Uniswap's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isUniswapUp" class="form-label">
            Is Uniswap going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isUniswapUp"
            name="isUniswapUp"
            onChange={onChange}
            value={details.isUniswapUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Near Protocol (NEAR)</h3>
        <div class="mb-3 my-3">
          <label for="nearProtocolPriceToday" class="form-label">
            NEAR Protocol Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="nearProtocolPriceToday"
            name="nearProtocolPriceToday"
            onChange={onChange}
            value={details.nearProtocolPriceToday}
            placeholder="Write NEAR Protocol's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="nearProtocolPriceTomorrow" class="form-label">
            NEAR Protocol Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="nearProtocolPriceTomorrow"
            name="nearProtocolPriceTomorrow"
            onChange={onChange}
            value={details.nearProtocolPriceTomorrow}
            placeholder="Write NEAR Protocol's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isNearProtocolUp" class="form-label">
            Is NEAR Protocol going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isNearProtocolUp"
            name="isNearProtocolUp"
            onChange={onChange}
            value={details.isNearProtocolUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Internet Protocol (ICP)</h3>
        <div class="mb-3 my-3">
          <label for="internetProtocolPriceToday" class="form-label">
            Internet Protocol Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="internetProtocolPriceToday"
            name="internetProtocolPriceToday"
            onChange={onChange}
            value={details.internetProtocolPriceToday}
            placeholder="Write Internet Protocol's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="internetProtocolPriceTomorrow" class="form-label">
            Internet Protocol Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="internetProtocolPriceTomorrow"
            name="internetProtocolPriceTomorrow"
            onChange={onChange}
            value={details.internetProtocolPriceTomorrow}
            placeholder="Write Internet Protocol's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isInternetProtocolUp" class="form-label">
            Is Internet Protocol going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isInternetProtocolUp"
            name="isInternetProtocolUp"
            onChange={onChange}
            value={details.isInternetProtocolUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Unus Sed Leo (LEO)</h3>
        <div class="mb-3 my-3">
          <label for="unusSedLeoPriceToday" class="form-label">
            LEO Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="unusSedLeoPriceToday"
            name="unusSedLeoPriceToday"
            onChange={onChange}
            value={details.unusSedLeoPriceToday}
            placeholder="Write LEO's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="unusSedLeoPriceTomorrow" class="form-label">
            LEO Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="unusSedLeoPriceTomorrow"
            name="unusSedLeoPriceTomorrow"
            onChange={onChange}
            value={details.unusSedLeoPriceTomorrow}
            placeholder="Write LEO's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isUnusSedLeoUp" class="form-label">
            Is LEO going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isUnusSedLeoUp"
            name="isUnusSedLeoUp"
            onChange={onChange}
            value={details.isUnusSedLeoUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Stellar (XLM)</h3>
        <div class="mb-3 my-3">
          <label for="stellerPriceToday" class="form-label">
            XLM Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="stellerPriceToday"
            name="stellerPriceToday"
            onChange={onChange}
            value={details.stellerPriceToday}
            placeholder="Write XLM's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="stellerPriceTomorrow" class="form-label">
            XLM Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="stellerPriceTomorrow"
            name="stellerPriceTomorrow"
            onChange={onChange}
            value={details.stellerPriceTomorrow}
            placeholder="Write XLM's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isStellerUp" class="form-label">
            Is XLM going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isStellerUp"
            name="isStellerUp"
            onChange={onChange}
            value={details.isStellerUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>OKB</h3>
        <div class="mb-3 my-3">
          <label for="okbPriceToday" class="form-label">
            OKB Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="okbPriceToday"
            name="okbPriceToday"
            onChange={onChange}
            value={details.okbPriceToday}
            placeholder="Write OKB's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="okbPriceTomorrow" class="form-label">
            OKB Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="okbPriceTomorrow"
            name="okbPriceTomorrow"
            onChange={onChange}
            value={details.okbPriceTomorrow}
            placeholder="Write OKB's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isOkbUp" class="form-label">
            Is OKB going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isOkbUp"
            name="isOkbUp"
            onChange={onChange}
            value={details.isOkbUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Injective Protocol (INJ)</h3>
        <div class="mb-3 my-3">
          <label for="injectivePriceToday" class="form-label">
            INJ Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="injectivePriceToday"
            name="injectivePriceToday"
            onChange={onChange}
            value={details.injectivePriceToday}
            placeholder="Write INJ's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="injectivePriceTomorrow" class="form-label">
            INJ Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="injectivePriceTomorrow"
            name="injectivePriceTomorrow"
            onChange={onChange}
            value={details.injectivePriceTomorrow}
            placeholder="Write INJ's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isInjectiveUp" class="form-label">
            Is INJ going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isInjectiveUp"
            name="isInjectiveUp"
            onChange={onChange}
            value={details.isInjectiveUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Monero (XMR)</h3>
        <div class="mb-3 my-3">
          <label for="moneroPriceToday" class="form-label">
            Monero Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="moneroPriceToday"
            name="moneroPriceToday"
            onChange={onChange}
            value={details.moneroPriceToday}
            placeholder="Write Monero's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="moneroPriceTomorrow" class="form-label">
            Monero Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="moneroPriceTomorrow"
            name="moneroPriceTomorrow"
            onChange={onChange}
            value={details.moneroPriceTomorrow}
            placeholder="Write Monero's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isMoneroUp" class="form-label">
            Is Monero going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isMoneroUp"
            name="isMoneroUp"
            onChange={onChange}
            value={details.isMoneroUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Aptos (APT)</h3>
        <div class="mb-3 my-3">
          <label for="aptosPriceToday" class="form-label">
            Aptos Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="aptosPriceToday"
            name="aptosPriceToday"
            onChange={onChange}
            value={details.aptosPriceToday}
            placeholder="Write Aptos' today price here..."
          />
        </div>

        <div class="mb-3 my-3">
          <label for="aptosPriceTomorrow" class="form-label">
            Aptos Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="aptosPriceTomorrow"
            name="aptosPriceTomorrow"
            onChange={onChange}
            value={details.aptosPriceTomorrow}
            placeholder="Write Aptos' tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isAptosUp" class="form-label">
            Is Aptos going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isAptosUp"
            name="isAptosUp"
            onChange={onChange}
            value={details.isAptosUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Ethereum Classic (ETC)</h3>
        <div class="mb-3 my-3">
          <label for="ethereumClassicPriceToday" class="form-label">
            Ethereum Classic Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="ethereumClassicPriceToday"
            name="ethereumClassicPriceToday"
            onChange={onChange}
            value={details.ethereumClassicPriceToday}
            placeholder="Write Ethereum Classic's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="ethereumClassicPriceTomorrow" class="form-label">
            Ethereum Classic Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="ethereumClassicPriceTomorrow"
            name="ethereumClassicPriceTomorrow"
            onChange={onChange}
            value={details.ethereumClassicPriceTomorrow}
            placeholder="Write Ethereum Classic's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isEthereumClassicUp" class="form-label">
            Is Ethereum Classic going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isEthereumClassicUp"
            name="isEthereumClassicUp"
            onChange={onChange}
            value={details.isEthereumClassicUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Hedera Hashgraph (HBAR)</h3>
        <div class="mb-3 my-3">
          <label for="hederaPriceToday" class="form-label">
            Hedera Hashgraph Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="hederaPriceToday"
            name="hederaPriceToday"
            onChange={onChange}
            value={details.hederaPriceToday}
            placeholder="Write Hedera Hashgraph's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="hederaPriceTomorrow" class="form-label">
            Hedera Hashgraph Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="hederaPriceTomorrow"
            name="hederaPriceTomorrow"
            onChange={onChange}
            value={details.hederaPriceTomorrow}
            placeholder="Write Hedera Hashgraph's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isHederaUp" class="form-label">
            Is Hedera Hashgraph going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isHederaUp"
            name="isHederaUp"
            onChange={onChange}
            value={details.isHederaUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Immutable X (IMX)</h3>
        <div class="mb-3 my-3">
          <label for="immutableXPriceToday" class="form-label">
            Immutable X Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="immutableXPriceToday"
            name="immutableXPriceToday"
            onChange={onChange}
            value={details.immutableXPriceToday}
            placeholder="Write Immutable X's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="immutableXPriceTomorrow" class="form-label">
            Immutable X Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="immutableXPriceTomorrow"
            name="immutableXPriceTomorrow"
            onChange={onChange}
            value={details.immutableXPriceTomorrow}
            placeholder="Write Immutable X's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isImmutableXUp" class="form-label">
            Is Immutable X going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isImmutableXUp"
            name="isImmutableXUp"
            onChange={onChange}
            value={details.isImmutableXUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Optimism (L2)</h3>
        <div class="mb-3 my-3">
          <label for="optimismPriceToday" class="form-label">
            Optimism Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="optimismPriceToday"
            name="optimismPriceToday"
            onChange={onChange}
            value={details.optimismPriceToday}
            placeholder="Write Optimism's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="optimismPriceTomorrow" class="form-label">
            Optimism Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="optimismPriceTomorrow"
            name="optimismPriceTomorrow"
            onChange={onChange}
            value={details.optimismPriceTomorrow}
            placeholder="Write Optimism's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isOptimismUp" class="form-label">
            Is Optimism going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isOptimismUp"
            name="isOptimismUp"
            onChange={onChange}
            value={details.isOptimismUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Filecoin (FIL)</h3>
        <div class="mb-3 my-3">
          <label for="filecoinPriceToday" class="form-label">
            Filecoin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="filecoinPriceToday"
            name="filecoinPriceToday"
            onChange={onChange}
            value={details.filecoinPriceToday}
            placeholder="Write Filecoin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="filecoinPriceTomorrow" class="form-label">
            Filecoin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="filecoinPriceTomorrow"
            name="filecoinPriceTomorrow"
            onChange={onChange}
            value={details.filecoinPriceTomorrow}
            placeholder="Write Filecoin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isFilecoinUp" class="form-label">
            Is Filecoin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isFilecoinUp"
            name="isFilecoinUp"
            onChange={onChange}
            value={details.isFilecoinUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>VeChain (VET)</h3>
        <div class="mb-3 my-3">
          <label for="veChainPriceToday" class="form-label">
            VeChain Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="veChainPriceToday"
            name="veChainPriceToday"
            onChange={onChange}
            value={details.veChainPriceToday}
            placeholder="Write VeChain's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="veChainPriceTomorrow" class="form-label">
            VeChain Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="veChainPriceTomorrow"
            name="veChainPriceTomorrow"
            onChange={onChange}
            value={details.veChainPriceTomorrow}
            placeholder="Write VeChain's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isVeChainUp" class="form-label">
            Is VeChain going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isVeChainUp"
            name="isVeChainUp"
            onChange={onChange}
            value={details.isVeChainUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Cronos (CRO)</h3>
        <div class="mb-3 my-3">
          <label for="cronosPriceToday" class="form-label">
            Cronos Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="cronosPriceToday"
            name="cronosPriceToday"
            onChange={onChange}
            value={details.cronosPriceToday}
            placeholder="Write Cronos's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="cronosPriceTomorrow" class="form-label">
            Cronos Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="cronosPriceTomorrow"
            name="cronosPriceTomorrow"
            onChange={onChange}
            value={details.cronosPriceTomorrow}
            placeholder="Write Cronos's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isCronosUp" class="form-label">
            Is Cronos going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isCronosUp"
            name="isCronosUp"
            onChange={onChange}
            value={details.isCronosUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>TrueUSD (TUSD)</h3>
        <div class="mb-3 my-3">
          <label for="trueUsdPriceToday" class="form-label">
            TrueUSD Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="trueUsdPriceToday"
            name="trueUsdPriceToday"
            onChange={onChange}
            value={details.trueUsdPriceToday}
            placeholder="Write TrueUSD's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="trueUsdPriceTomorrow" class="form-label">
            TrueUSD Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="trueUsdPriceTomorrow"
            name="trueUsdPriceTomorrow"
            onChange={onChange}
            value={details.trueUsdPriceTomorrow}
            placeholder="Write TrueUSD's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isTrueUsdUp" class="form-label">
            Is TrueUSD going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isTrueUsdUp"
            name="isTrueUsdUp"
            onChange={onChange}
            value={details.isTrueUsdUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Kaspa Coin (KAS)</h3>
        <div class="mb-3 my-3">
          <label for="kaspaPriceToday" class="form-label">
            Kaspa Coin Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="kaspaPriceToday"
            name="kaspaPriceToday"
            onChange={onChange}
            value={details.kaspaPriceToday}
            placeholder="Write Kaspa Coin's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="kaspaPriceTomorrow" class="form-label">
            Kaspa Coin Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="kaspaPriceTomorrow"
            name="kaspaPriceTomorrow"
            onChange={onChange}
            value={details.kaspaPriceTomorrow}
            placeholder="Write Kaspa Coin's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isKaspaUp" class="form-label">
            Is Kaspa Coin going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isKaspaUp"
            name="isKaspaUp"
            onChange={onChange}
            value={details.isKaspaUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Lido DAO Token (LDO)</h3>
        <div class="mb-3 my-3">
          <label for="lidoDaoTokenPriceToday" class="form-label">
            Lido DAO Token Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="lidoDaoTokenPriceToday"
            name="lidoDaoTokenPriceToday"
            onChange={onChange}
            value={details.lidoDaoTokenPriceToday}
            placeholder="Write Lido DAO Token's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="lidoDaoTokenPriceTomorrow" class="form-label">
            Lido DAO Token Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="lidoDaoTokenPriceTomorrow"
            name="lidoDaoTokenPriceTomorrow"
            onChange={onChange}
            value={details.lidoDaoTokenPriceTomorrow}
            placeholder="Write Lido DAO Token's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isLidoDaoTokenUp" class="form-label">
            Is Lido DAO Token going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isLidoDaoTokenUp"
            name="isLidoDaoTokenUp"
            onChange={onChange}
            value={details.isLidoDaoTokenUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Stacks (STX)</h3>
        <div class="mb-3 my-3">
          <label for="stacksPriceToday" class="form-label">
            Stacks Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="stacksPriceToday"
            name="stacksPriceToday"
            onChange={onChange}
            value={details.stacksPriceToday}
            placeholder="Write Stacks's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="stacksPriceTomorrow" class="form-label">
            Stacks Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="stacksPriceTomorrow"
            name="stacksPriceTomorrow"
            onChange={onChange}
            value={details.stacksPriceTomorrow}
            placeholder="Write Stacks's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isStacksUp" class="form-label">
            Is Stacks going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isStacksUp"
            name="isStacksUp"
            onChange={onChange}
            value={details.isStacksUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Celestia (TIA)</h3>
        <div class="mb-3 my-3">
          <label for="celestiaPriceToday" class="form-label">
            Celestia Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="celestiaPriceToday"
            name="celestiaPriceToday"
            onChange={onChange}
            value={details.celestiaPriceToday}
            placeholder="Write Celestia's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="celestiaPriceTomorrow" class="form-label">
            Celestia Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="celestiaPriceTomorrow"
            name="celestiaPriceTomorrow"
            onChange={onChange}
            value={details.celestiaPriceTomorrow}
            placeholder="Write Celestia's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isCelestiaUp" class="form-label">
            Is Celestia going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isCelestiaUp"
            name="isCelestiaUp"
            onChange={onChange}
            value={details.isCelestiaUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Algorand (ALGO)</h3>
        <div class="mb-3 my-3">
          <label for="algorandPriceToday" class="form-label">
            Algorand Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="algorandPriceToday"
            name="algorandPriceToday"
            onChange={onChange}
            value={details.algorandPriceToday}
            placeholder="Write Algorand's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="algorandPriceTomorrow" class="form-label">
            Algorand Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="algorandPriceTomorrow"
            name="algorandPriceTomorrow"
            onChange={onChange}
            value={details.algorandPriceTomorrow}
            placeholder="Write Algorand's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isAlgorandUp" class="form-label">
            Is Algorand going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isAlgorandUp"
            name="isAlgorandUp"
            onChange={onChange}
            value={details.isAlgorandUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Mantle (MNT)</h3>
        <div class="mb-3 my-3">
          <label for="mantlePriceToday" class="form-label">
            Mantle Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="mantlePriceToday"
            name="mantlePriceToday"
            onChange={onChange}
            value={details.mantlePriceToday}
            placeholder="Write Mantle's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="mantlePriceTomorrow" class="form-label">
            Mantle Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="mantlePriceTomorrow"
            name="mantlePriceTomorrow"
            onChange={onChange}
            value={details.mantlePriceTomorrow}
            placeholder="Write Mantle's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isMantleUp" class="form-label">
            Is Mantle going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isMantleUp"
            name="isMantleUp"
            onChange={onChange}
            value={details.isMantleUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>ThorChain (RUNE)</h3>
        <div class="mb-3 my-3">
          <label for="thorChainPriceToday" class="form-label">
            ThorChain Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="thorChainPriceToday"
            name="thorChainPriceToday"
            onChange={onChange}
            value={details.thorChainPriceToday}
            placeholder="Write ThorChain's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="thorChainPriceTomorrow" class="form-label">
            ThorChain Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="thorChainPriceTomorrow"
            name="thorChainPriceTomorrow"
            onChange={onChange}
            value={details.thorChainPriceTomorrow}
            placeholder="Write ThorChain's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isThorChainUp" class="form-label">
            Is ThorChain going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isThorChainUp"
            name="isThorChainUp"
            onChange={onChange}
            value={details.isThorChainUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>MultiversX (EGLD)</h3>
        <div class="mb-3 my-3">
          <label for="multiversXPriceToday" class="form-label">
            MultiversX Price Today
          </label>
          <input
            type="text"
            class="form-control"
            id="multiversXPriceToday"
            name="multiversXPriceToday"
            onChange={onChange}
            value={details.multiversXPriceToday}
            placeholder="Write MultiversX's today price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="multiversXPriceTomorrow" class="form-label">
            MultiversX Price Tomorrow
          </label>
          <input
            type="text"
            class="form-control"
            id="multiversXPriceTomorrow"
            name="multiversXPriceTomorrow"
            onChange={onChange}
            value={details.multiversXPriceTomorrow}
            placeholder="Write MultiversX's tomorrow price here..."
          />
        </div>
        <div class="mb-3 my-3">
          <label for="isMultiversXUp" class="form-label">
            Is MultiversX going up or not (Tomorrow)
          </label>
          <input
            type="text"
            class="form-control"
            id="isMultiversXUp"
            name="isMultiversXUp"
            onChange={onChange}
            value={details.isMultiversXUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>RenderToken ($RND)</h3>
        <div className="mb-3 my-3">
          <label htmlFor="renderTokenPriceToday" className="form-label">
            RenderToken Price Today
          </label>
          <input
            type="text"
            className="form-control"
            id="renderTokenPriceToday"
            name="renderTokenPriceToday"
            onChange={onChange}
            value={details.renderTokenPriceToday}
            placeholder="Write RenderToken's today price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="renderTokenPriceTomorrow" className="form-label">
            RenderToken Price Tomorrow
          </label>
          <input
            type="text"
            className="form-control"
            id="renderTokenPriceTomorrow"
            name="renderTokenPriceTomorrow"
            onChange={onChange}
            value={details.renderTokenPriceTomorrow}
            placeholder="Write RenderToken's tomorrow price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="isRenderTokenUp" className="form-label">
            Is RenderToken going up or not (Tomorrow)
          </label>
          <input
            type="text"
            className="form-control"
            id="isRenderTokenUp"
            name="isRenderTokenUp"
            onChange={onChange}
            value={details.isRenderTokenUp}
            placeholder="Yes or No"
          />
        </div>
        <h3>The Graph (GRT)</h3>
        <div className="mb-3 my-3">
          <label htmlFor="theGraphPriceToday" className="form-label">
            The Graph Price Today
          </label>
          <input
            type="text"
            className="form-control"
            id="theGraphPriceToday"
            name="theGraphPriceToday"
            onChange={onChange}
            value={details.theGraphPriceToday}
            placeholder="Write The Graph's today price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="theGraphPriceTomorrow" className="form-label">
            The Graph Price Tomorrow
          </label>
          <input
            type="text"
            className="form-control"
            id="theGraphPriceTomorrow"
            name="theGraphPriceTomorrow"
            onChange={onChange}
            value={details.theGraphPriceTomorrow}
            placeholder="Write The Graph's tomorrow price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="isTheGraphUp" className="form-label">
            Is The Graph going up or not (Tomorrow)
          </label>
          <input
            type="text"
            className="form-control"
            id="isTheGraphUp"
            name="isTheGraphUp"
            onChange={onChange}
            value={details.isTheGraphUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>FirstDigitalUSD (FDUSD)</h3>
        <div className="mb-3 my-3">
          <label htmlFor="firstDigitalUsdPriceToday" className="form-label">
            FirstDigitalUSD Price Today
          </label>
          <input
            type="text"
            className="form-control"
            id="firstDigitalUsdPriceToday"
            name="firstDigitalUsdPriceToday"
            onChange={onChange}
            value={details.firstDigitalUsdPriceToday}
            placeholder="Write FirstDigitalUSD's today price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="firstDigitalUsdPriceTomorrow" className="form-label">
            FirstDigitalUSD Price Tomorrow
          </label>
          <input
            type="text"
            className="form-control"
            id="firstDigitalUsdPriceTomorrow"
            name="firstDigitalUsdPriceTomorrow"
            onChange={onChange}
            value={details.firstDigitalUsdPriceTomorrow}
            placeholder="Write FirstDigitalUSD's tomorrow price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="isFirstDigitalUsdUp" className="form-label">
            Is FirstDigitalUSD going up or not (Tomorrow)
          </label>
          <input
            type="text"
            className="form-control"
            id="isFirstDigitalUsdUp"
            name="isFirstDigitalUsdUp"
            onChange={onChange}
            value={details.isFirstDigitalUsdUp}
            placeholder="Yes or No"
          />
        </div>

        <h3>Quant (QNT)</h3>
        <div className="mb-3 my-3">
          <label htmlFor="quantPriceToday" className="form-label">
            Quant Price Today
          </label>
          <input
            type="text"
            className="form-control"
            id="quantPriceToday"
            name="quantPriceToday"
            onChange={onChange}
            value={details.quantPriceToday}
            placeholder="Write Quant's today price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="quantPriceTomorrow" className="form-label">
            Quant Price Tomorrow
          </label>
          <input
            type="text"
            className="form-control"
            id="quantPriceTomorrow"
            name="quantPriceTomorrow"
            onChange={onChange}
            value={details.quantPriceTomorrow}
            placeholder="Write Quant's tomorrow price here..."
          />
        </div>
        <div className="mb-3 my-3">
          <label htmlFor="isQuantUp" className="form-label">
            Is Quant going up or not (Tomorrow)
          </label>
          <input
            type="text"
            className="form-control"
            id="isQuantUp"
            name="isQuantUp"
            onChange={onChange}
            value={details.isQuantUp}
            placeholder="Yes or No"
          />
        </div>

        <button onClick={submitBtn} class="btn btn-primary mb-3">
          Update Prices
        </button>

        <div
          style={{ display: `${display}` }}
          class="alert alert-primary my-3"
          role="alert"
        >
          {message}
        </div>
      </div>
    </div>
  );
}
