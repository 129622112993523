import "./App.css";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import Navbar from "./Components/Navbar";
import AddPrices from "./Components/AddPrices";
import AddBlog from "./Components/AddBlog";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="d-flex flex-column min-vh-100">
          <Navbar />
          <div className="container flex-grow-1" style={{ marginTop: "80px" }}>
            <Routes>
              <Route exact path="/" element={<AddPrices />} />
              <Route exact path="/addBlog" element={<AddBlog />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
