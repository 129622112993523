import React, { useState } from "react";

export default function AddBlog() {
  const [details, setDetails] = useState({
    title: "",
    imageLink: "",
    introduction: "",
    previousPrices: "",
    marketDemand: "",
    prediction: "",
    conclusion: "",
    disclaimer: "",
  });

  const [message, setMessage] = useState("uploading blog, please wait...");
  const [display, setDisplay] = useState("none");

  const onChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const publishBlog = async () => {
    setDisplay("block");
    setMessage("uploading blog, please wait...");
    try {
      const host = "https://cryptopredicts.live:1000";
      const data = await fetch(`${host}/api/v1/news/addNews`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: details.title,
          imageLink: details.imageLink,
          introduction: details.introduction,
          previousPrices: details.previousPrices,
          marketDemand: details.marketDemand,
          prediction: details.prediction,
          conclusion: details.conclusion,
          disclaimer: details.disclaimer,
        }),
      });
      const response = await data.json();
      console.log(response);
      alert("Blog was uploaded successfully.");
    } catch (e) {
      console.log(e);
      alert("inside catch");
      alert(
        "Some error occurred while uploading blog. Please check your connection and try again"
      );
    }
  };

  const alert = (message) => {
    setMessage(`${message}`);
    setTimeout(() => {
      setDisplay("none");
    }, 8000);
  };

  return (
    <div>
      <h1 className="my-2 text-center">Add Blog</h1>

      <div className="mb-3 my-3">
        <label for="title" className="form-label">
          Blog Title:
        </label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={details.title}
          onChange={onChange}
          placeholder="enter blog title here..."
        />
      </div>
      <div className="mb-3 my-3">
        <label for="imageLink" className="form-label">
          Image Link:
        </label>
        <input
          type="text"
          className="form-control"
          id="imageLink"
          name="imageLink"
          value={details.imageLink}
          onChange={onChange}
          placeholder="enter image link here..."
        />
      </div>
      <div className="mb-3">
        <label for="introduction" className="form-label">
          Introduction:
        </label>
        <textarea
          className="form-control"
          id="introduction"
          name="introduction"
          value={details.introduction}
          onChange={onChange}
          placeholder="Write introduction of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="mb-3">
        <label for="previousPrices" className="form-label">
          Previous Prices:
        </label>
        <textarea
          className="form-control"
          id="previousPrices"
          name="previousPrices"
          value={details.previousPrices}
          onChange={onChange}
          placeholder="Write previous prices details of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="mb-3">
        <label for="marketDemand" className="form-label">
          Market Demand:
        </label>
        <textarea
          className="form-control"
          id="marketDemand"
          name="marketDemand"
          value={details.marketDemand}
          onChange={onChange}
          placeholder="Write market demand details of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="mb-3">
        <label for="prediction" className="form-label">
          Prediction:
        </label>
        <textarea
          className="form-control"
          id="prediction"
          name="prediction"
          value={details.prediction}
          onChange={onChange}
          placeholder="Write prediction details of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="mb-3">
        <label for="conclusion" className="form-label">
          Conclusion:
        </label>
        <textarea
          className="form-control"
          id="conclusion"
          name="conclusion"
          value={details.conclusion}
          onChange={onChange}
          placeholder="Write conclusion details of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="mb-3">
        <label for="disclaimer" className="form-label">
          Disclaimer:
        </label>
        <textarea
          className="form-control"
          id="disclaimer"
          name="disclaimer"
          value={details.disclaimer}
          onChange={onChange}
          placeholder="Write disclaimer of the Blog here..."
          rows="5"
        ></textarea>
      </div>

      <div className="col-auto">
        <button onClick={publishBlog} className="btn btn-primary mb-3">
          Publish Blog
        </button>
      </div>

      <div
        style={{ display: `${display}` }}
        class="alert alert-primary my-3"
        role="alert"
      >
        {message}
      </div>
    </div>
  );
}
